import styled from 'styled-components';
import Container from '@quantore/gatsby-theme-quantore/src/components/Container';
import Typography from '@quantore/gatsby-theme-quantore/src/typography';
import { ParagraphContainer } from '@quantore/gatsby-theme-quantore/src/components/Paragraph/styles';

export const Main = styled.main`
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    ${ParagraphContainer} {
      margin: 0 -20px;
    }
  }
`;

export const PageContainer = styled(Container)`
  [class^='Container__Wrapper'] {
    padding-right: 0;
    padding-left: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    section {
      padding: 32px 0;
    }
  }
`;

export const ContentContainer = styled(Container)`
  padding-top: 30px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding-top: 72px;
    ${({ padding }) => (padding ? 'padding-right: 8.3333%;' : '')}
  }
`;

export const PreTitle = styled.div`
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.brand};
  font-family: AdelleSansLight;
`;

export const PageTitle = styled.h1`
  ${Typography.h2}
  margin-top: 0;
  margin-bottom: 40px;
  color: ${(props) => props.theme.colors.black};

  ${({ limit, theme }) =>
    limit
      ? `
    @media (min-width: ${theme.breakpoints.l}) {
      max-width: 60%;
      line-height: 4.5rem;
      margin-bottom: 0;
    }
  `
      : ''}
`;

export const Content = styled.div`
  margin-bottom: ${({ margin }) => (margin ? '40px' : '8px')};

  p {
    font-family: AdelleSansLight;
    font-size: 22px;
    line-height: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
export const Date = styled.span`
  display: inline-block;
  margin-left: 14px;
  color: ${(props) => props.theme.colors.greyDark};
`;

export const Image = styled.div`
  position: relative;
  margin-bottom: 25px;

  @media all and (-ms-high-contrast: none) {
    height: 0;
    padding-bottom: 52.67%;

    > svg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: 0 auto;
    }
  }

  > svg {
    margin-top: 30px;
  }
`;

export const Caption = styled.figcaption`
  ${Typography.caption};
  position: absolute;
  top: 35px;
  left: 45px;
  right: 45px;
  z-index: 1;
  text-align: right;
`;

export const Filters = styled.div`
  display: flex;
  margin: 0 0 40px;
  padding-top: 30px;
`;

export const FilterButton = styled.button`
  ${Typography.label}
  position: relative;
  cursor: pointer;
  padding: 15px 25px;
  color: ${({ theme }) => theme.colors.black};
  font-family: AdelleRegular;
  border-radius: 6px;
  border: none;
  background-color: transparent;
  overflow: hidden;

  ${({ active, theme }) =>
    active
      ? `
    background-color: ${theme.colors.greyLightest};

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 4px;
      background-color: ${theme.colors.brand};
    }
  `
      : ''}
`;
