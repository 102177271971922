import React from 'react';
import Helmet from 'react-helmet';
import loadable from '@loadable/component';
import { renderContent } from '@quantore/gatsby-theme-quantore/src/utils';
import Breadcrumb from '@quantore/gatsby-theme-quantore/src/components/Breadcrumb/Breadcrumb';
import PageLoader from '@quantore/gatsby-theme-quantore/src/components/Loader/PageLoader';
import { graphql } from 'gatsby';
import FadeIn from 'react-fade-in';
import VacancyImage from '@quantore/gatsby-theme-quantore/src/images/Quantore-branding-vacatures.jpg';

import {
  Main,
  PageContainer,
  ContentContainer,
  PageTitle,
  PreTitle,
  Content,
} from './styles';

const Image = loadable(
  () => import('@quantore/gatsby-theme-quantore/src/components/Image/Image'),
  {
    fallback: <PageLoader />,
    cacheKey: () => 'Image',
  }
);

const ArticleTeaser = loadable(
  () =>
    import(
      '@quantore/gatsby-theme-quantore/src/components/ArticleTeaser/ArticleTeaser'
    ),
  {
    fallback: <PageLoader />,
    cacheKey: () => 'ArticleTeaser',
  }
);

const ComponentList = loadable(
  () =>
    import(
      '@quantore/gatsby-theme-quantore/src/components/ComponentList/ComponentList'
    ),
  {
    fallback: <PageLoader />,
    cacheKey: () => 'ComponentList',
  }
);

const DetailPage = ({
  pageContext,
  location,
  data: { contentfulDetailPage },
}) => {
  const {
    breadcrumbLabel,
    title,
    preTitle,
    richDescription,
    image,
    components,
    relatedArticles,
    metaTagTitle,
    metaTagDescription,
    metaTagRobots,
  } = contentfulDetailPage;

  return (
    <>
      <Helmet>
        <title>{metaTagTitle ? metaTagTitle : title}</title>
        <link rel="canonical" href={location.href} />
        <meta name="title" content={metaTagTitle ? metaTagTitle : title} />
        <meta
          name="robots"
          content={metaTagRobots ? metaTagRobots : 'index, follow'}
        />
        {metaTagDescription && (
          <meta name="description" content={metaTagDescription} />
        )}

        <meta property="og:url" content={location.href} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={metaTagTitle ? metaTagTitle : title}
        />
        {metaTagDescription && (
          <meta property="og:description" content={metaTagDescription} />
        )}
        {image && image.file && image.file.url ? (
          <meta property="og:image" content={image.file.url} />
        ) : (
          <meta property="og:image" content={VacancyImage} />
        )}

        <meta property="twitter:url" content={location.href} />
        <meta
          property="twitter:title"
          content={metaTagTitle ? metaTagTitle : title}
        />
        <meta property="twitter:card" content="summary_large_image" />
        {metaTagDescription && (
          <meta property="twitter:description" content={metaTagDescription} />
        )}
        {image && image.file && image.file.url ? (
          <meta property="twitter:image" content={image.file.url} />
        ) : (
          <meta property="twitter:image" content={VacancyImage} />
        )}
      </Helmet>

      <Breadcrumb breadcrumb={pageContext.breadcrumb} label={breadcrumbLabel} />

      <ContentContainer size="s" padding>
        <FadeIn>
          {preTitle && <PreTitle>{preTitle}</PreTitle>}

          <PageTitle>{title}</PageTitle>

          {richDescription && richDescription.raw && (
            <Content margin={false}>{renderContent(richDescription)}</Content>
          )}
        </FadeIn>
      </ContentContainer>

      <Main>
        <PageContainer size="s">
          {image && image.file && image.file.url && <Image image={image} />}
          {components && <ComponentList components={components} />}
        </PageContainer>
      </Main>

      {relatedArticles && (
        <aside>
          <ArticleTeaser
            list={relatedArticles.list}
            title={relatedArticles.title}
          />
        </aside>
      )}
    </>
  );
};

export default DetailPage;

export const query = graphql`
  query DetailPageQuery($id: String!) {
    contentfulDetailPage(id: { eq: $id }) {
      ...DetailPageFragment
    }
  }
`;
